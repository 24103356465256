@import '../../4_styles/config';
@import '../../4_styles/mixins';

.sm-page-home {


    &__image {
        @include center;
        opacity: 0;            
        animation: load-image .3s forwards ease-out;
        animation-delay: .6s;
        z-index: -1;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &-portrait {
            width: 30vw;
            height: calc(30vw / 3 * 4);
        }

        &-landscape {
            width: 50vw;
            height: calc(50vw / 5 * 3);
        }
    }

    @media screen and (max-width: $breakpoint-medium) {
        
        .sm-page-home__image {
            // @include center;
            // opacity: 0;            
            // animation: load-image .3s forwards ease-out;
            // animation-delay: .6s;
            // z-index: -1;

            img {
                // width: 100%;
                // height: 100%;
                // object-fit: cover;
            }

            &-portrait {
                width: calc(75vw - 4px);
                height: calc((75vw - 4px) / 3 * 4);
            }

            &-landscape {
                width: calc(100vw - 4px);
                height: calc((100vw - 4px) / 5 * 3);
            }
        }
    }
}

@keyframes load-image {
    from {opacity: 0}
    to {opacity: 1}
}
