@import '../../4_styles/config';

.sm-page-project {

    &__infos {
        display: flex;
        flex-wrap: wrap;

        &__info {
            font-size: 11.5px;
            font-family: 'Haas Text', sans-serif;
            margin-bottom: $spacing-medium;
            margin-right: $spacing-side;
            text-transform: uppercase;

            &::after {
                content: ' • ';
                margin: 0 3px;
            }

            &:last-of-type::after {
                display: none;  
            }
        }
    }

    &__medium {

        .iframe-container {

            iframe {
                // pointer-events: none;
            }
        }

        &.fullscreen {
            position: fixed;
            top: 0;
            width: 100%;
            height: 100vh;

            .iframe-container {

                width: 100%;
                height: 100%;
                background: $gray;
                
                iframe {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .vp-controls-wrapper {
            display: none;
        }
    }
}

@media screen and (max-width: $breakpoint-medium) {
    iframe {
        max-height: 100%;
        max-width: 100%;
    }
}