@import '../../../4_styles/config';

.sm-player {

    display: flex;
    margin-top: $spacing-small;

    audio {
        display: none;
    }

    button, .sm-button {
        background: $black;
        color: $yellow;
    }

    &__item {

        width: 50px;

        &-home, &-lang {
            transition: $transition-long;
            &:hover {
                background: $yellow;
                color: $black;
                border-color: $black;
            }
        }

        &-home, &-name, &-progress {
            flex: 1;
            width: unset;
        }

        &-time {
            word-break: normal;
        }

        &-progress {
            display: flex;
            align-items: center;

            &__bar {
                height: 4px;
                width: 100%;
                background: $yellow;
                transform-origin: left center;
                // transition: $transition;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-medium) {

    .sm-player {

        // display: flex;
        // margin-top: $spacing-small;
        position: absolute;
        bottom: 0;
        flex-wrap: wrap;
        width: 100%;
        // padding-bottom: $spacing-small;
        background: $gray;
        z-index: 1;

        audio {
            // display: none;
        }
    
        button, .sm-button {
            // background: $black;
            // color: $yellow;
        }
    
        &__item {
    
            // width: 50px;
            margin-bottom: $spacing-small;
    
            &-home, &-lang {
                // transition: $transition-long;
                &:hover {
                    // background: $yellow;
                    // color: $black;
                    // border-color: $black;
                }
            }

            &-name {
                order: -1;
                width: 100%;
                flex: unset;
            }

            &-prev, &-play, &-next, &-lang {
                flex: 1;
            }

            &-up, &-down, &-time, &-progress {
                display: none;
            }
    
            &-home, &-name, &-progress {
                // flex: 1;
                // width: unset;
            }
    
            &-time {
                // word-break: normal;
            }
    
            &-progress {
                // display: flex;
                // align-items: center;
    
                &__bar {
                    // height: 4px;
                    // width: 100%;
                    // background: $yellow;
                    // transform-origin: left center;
                }
            }
        }
    } 
}