@import '../../4_styles/config';

.sm-page-news {
    &__list {
        &__item {
            margin-bottom: $spacing-bottom;

            &__date, &__text {
                margin-left: calc(#{$spacing-side} / 2);
                margin-right: calc(#{$spacing-side} / 2);
            }

        }
    }
}

@media screen and (max-width: $breakpoint-medium) {

    .sm-page-news {
        &__list {
            &__item {
                // margin-bottom: $spacing-bottom;
    
                &__date, &__text {
                    // margin-left: calc(#{$spacing-side} / 2);
                    // margin-right: calc(#{$spacing-side} / 2);
                }
    
                &__links {
                    // display: flex;
                    flex-wrap: wrap;
    
                    .sm-button {
                        flex: none;
                        // max-width: 25%;
                        width: 100%;
                        width: calc(100vw - 2*#{$spacing-medium});
                        margin-top: $spacing-small;
                    }
                }
            }
        }
    }
}