@import '../../../4_styles/config';

.sm-loading {

    // display: none;

    position: fixed;
    width: 100vw;
    max-width: unset;
    height: 100vh;
    background: $gray;
    z-index: 100;
    top: 0;
    left: 0;
    padding: $spacing-small $spacing-side;

    &__bar {
        width: 100%;
        height: 24px;
        border-radius: 12px;
        border: 0.75px solid black;

        &__progress {
            width: 0;
            height: 100%;
            background: black;
            border-radius: 8.75px;
            animation: loading-bar 2s forwards ease-out;
        }
    }
}

@media screen and (max-width: $breakpoint-medium) {
    .sm-loading__bar {
        height: 29px;
        border-radius: 14.5px;
    }
}

@keyframes loading-bar {
    from {width: 0}
    to {width: 99%}
}