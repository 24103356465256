@import '../../4_styles/config';

.sm-page-contact {
    .sm-button {
        max-width: 25%;
    }

    &__text {
        margin-bottom: $spacing-bottom;
    }
}
@media screen and (max-width: $breakpoint-medium) {

    .sm-page-contact {
        .sm-button {
            max-width: calc(100vw - 2*#{$spacing-medium});
            width: 100%;
            margin: 0;
        }

        &__text {
            // margin-bottom: $spacing-bottom;
        }
    }
}