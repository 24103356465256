//==================
// Breakpoints
//==================

$breakpoint-medium: 768px;

//==================
// Spacing
//==================

$spacing-side: 3px;
$spacing-small: 4px;
$spacing-medium: 5px;
$spacing-large: 6px;
$spacing-bottom: 34px;

//==================
// Sizes
//==================

$outline-width: 0.75px;

//==================
// Colors
//==================

$black: black;
$gray: rgb(227,227,227);
$gray-text: rgb(218,218,218);
$yellow: yellow;
$white: white;

//==================
// Fonts
//==================

@font-face {
    font-family: 'Haas Display';
    src: url('../5_assests/fonts/NHaasGroteskDSPro-55Rg.otf')  format('truetype')
}

@font-face {
    font-family: 'Haas Text';
    src: url('../5_assests/fonts/NHaasGroteskTXPro-75Bd.otf')  format('truetype')
}


//==================
// Other
//==================

$transition: .1s;
$transition-long: .3s;