@import './4_styles/config';
@import './4_styles/mixins';


* {
  font-family: 'Haas Display', sans-serif;
  box-sizing: border-box;
  max-width: calc(100vw - 2*#{$spacing-side});
}

::selection {
  background: $yellow;
}
::-moz-selection {
  background: $yellow;
}

@media screen and (max-width: $breakpoint-medium) {
  * {
    font-family: 'Haas Display', sans-serif;
    box-sizing: border-box;
    max-width: calc(100vw - 4px);
  }
}

//==================
// 1 Atoms
//==================

ul, li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

p {
  margin: 0;
}

html {
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
}

body {
  // margin: 0 calc(#{$spacing-side} / 2);
  margin: 0;
  background: $gray;
  max-width: 100vw;
  overflow: hidden;
}

.App {
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
}

.sm-button {
  font-family: 'Haas Text', sans-serif;
  min-height: 24px;
  font-size: 12.5px;
  margin: 0 calc(#{$spacing-side} / 2);
  border-radius: 80px;
  border: 1px solid black;
  outline: none;
  background: $white;
  padding: 5px 14px 4px 14px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: $black;
  line-height: 100%;
  transition: $transition;    
  word-break: break-word;
  text-align: center;
  max-height: 200px;
  overflow: hidden;

  a {
    font-size: 11.5px;
    font-family: 'Haas Text', sans-serif;
    text-decoration: none;
    color: black;
  }

  svg {
    width: 13px;
    height: 13px;
    transition: $transition;
  }

  &:hover svg {
    transform: scale(1.2);
  }

  &.inactive {
    color: $gray-text;
    border-color: $white;
    transition: $transition-long;

    &:hover {
      color: $black;
      border-color: $black;
    }
  }

  &-no-interaction {
    pointer-events: none;
  }

  &.sm-track {
    background: $yellow;

    &::before {
      content: ' ';
      @include bg('icon_track.svg');
    }
  }
}

.copy {
  margin-bottom: $spacing-medium;
  font-size: 29px;
  line-height: 34px;

  p {
    margin-bottom: 34px;
  }
}

@media screen and (max-width: $breakpoint-medium) {
  .copy {
    font-size: 16px;
    line-height: 21px;
  }

  .sm-button {
    font-size: 14px;
    padding: 7px 14px 6px 14px;
  }
}

//==================
// 2 Molecules
//==================

.image-container {

  width: 100%;
  max-width: calc(100vw - 2*#{$spacing-side});
  animation: load-image .3s forwards ease-out;
  animation-delay: .6s;
  opacity: 0;
  
  img {
      object-fit: contain;
      object-position: top left;
      width: 50%;
      height: 100%;
  }
}

.link-list {
  display: flex;

  &:hover .sm-button {
      color: $gray-text;
      border-color: $white;
      transition: $transition-long;
  }

  .sm-button {
      width: calc(25% - 2px);

      &:hover {
          color: $black;
          border-color: $black;
      }
  }
}

@media screen and (max-width: $breakpoint-medium) {

  .sm-button {
    &.collapsed {
      max-height: 0;
      min-height: unset;
      padding: 0;
      border-width: 0;
      margin: 0 !important;
    }
  }

  .image-container {
    // max-width: calc(100vw - 2*#{$spacing-medium});
  
    img {
        width: 100%;
    }
  }
}


//==================
// 3 Elements
//==================

.sm-page {
  width: 100%; 
  height: 100%;
  overflow-y: scroll;
  margin: 0 calc(#{$spacing-side} / 2);
  padding-top: $spacing-medium;
  padding-bottom: $spacing-medium;
  transition: padding-top $transition-long;

  &.scrolled {
    // padding-top: 100px;
    // padding-bottom: 100vh;
  }
}

@media screen and (max-width: $breakpoint-medium) {
  .sm-page {
    margin: 0 calc(#{$spacing-medium} / 2);
    padding-top: $spacing-medium;
    // max-height: calc(100% - 52px);
  }
}



@keyframes load-image {
    from {opacity: 0}
    to {opacity: 1}
}