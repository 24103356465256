@mixin bg($image) {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: '../../../assets/img/#{$image}';
}

@mixin center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}