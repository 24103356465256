@import '../../../4_styles/config';

.sm-header {
    width: 100%;
    max-width: calc(100vw - 1*#{$spacing-side});

    * {
        max-width: calc(100vw - 1*#{$spacing-side});
    }

    &.fullscreen {
        position: fixed;
        top: 0;
        z-index: 10;
    }

    &__item-home {
        background: $black;
        color: $yellow;
        width: 100%;
        margin-top: $spacing-small;
    }

    &__menu {

        &__list {

            display: flex;
            overflow: hidden;
            max-height: calc(24px * 4);
            max-height: unset;
            // transition: $transition-long;

            &:hover .sm-button {
                color: $gray-text;
                border-color: $white;
                transition: $transition-long;
            }

            .sm-button {
                flex: 1;
                margin-top: $spacing-medium;
                
                &.active {
                    color: $black !important;
                    border-color: $black !important;
                }

                &:hover {
                    color: $black;
                    border-color: $black;
                }
            }

            &-projects {

                flex-wrap: wrap;
                
                .sm-button {
                    flex: unset;
                    width: calc(20% - 3px);
                }
            }

            &-tracks {

                flex-wrap: wrap;

                .sm-button {
                    flex: unset;
                    width: calc(25% - 3px);

                    svg {
                        transform: scale(0.75);    
                        margin-right: 2px;
                    }
                }

                .sm-track-trailer {
                    flex: 1;
                }
            }


            &.hidden {
                max-height: 0;
                margin: 0;
            }

        }
    }
}

@media screen and (max-width: $breakpoint-medium) {

    .sm-header {
        // width: 100%;
        // display: flex;
        // flex-wrap: wrap;
    
        &.fullscreen {
            // position: fixed;
            // top: 0;
            // z-index: 10;
        }
    
        &__menu {
    
            &__list {
    
                // display: flex;
                // margin-top: $spacing-small;
                // overflow: hidden;
                // max-height: calc(19px * 4);
                // // transition: $transition-long;
                display: flex;
                flex-wrap: wrap;
    
                .sm-button {
                    
                    min-width: 40%;
                    flex: 1;
                    margin-top: 7px;
                }
    
                &-projects {
                    .sm-button {
                        // max-width: calc(50% - #{$spacing-medium});
                        flex: unset;
                        max-width: calc(100% - #{$spacing-medium});
                        width: 100%;
                    }
                }


                &-tracks {
                    .sm-button {
                        flex: unset;
                        width: 100%;
                    }
                }
    
    
                &.hidden {
                    // max-height: 0;
                    // margin: 0;
                }
    
                // &-main {
                //     display: flex;
                //     flex-wrap: wrap;

                //     .sm-button {
                //         min-width: 40%;
                //         flex: 1;
                //     }
                // }
            }
        }
    }
}

// @keyframes load-tracks {
//     from {opacity: 0; max-height: 0;}
//     to {opacity: 1; max-height: 100px;}
// }